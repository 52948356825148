import {
  Folder,
  FoldersCreateRequest,
  FoldersListPaginatedResponse,
  FoldersUpdateRequest,
} from '@mockingjay-io/shared-dependencies/src/types/api/folders';
import authState from '@/store/auth';
import { SortFilterLimit } from '@mockingjay-io/shared-dependencies/src/types/api/generic';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { GenericErrorResponse } from '@mockingjay-io/shared-dependencies/src/types/api/errors';
import { queryClient } from '@/services/index';
import omit from 'lodash/omit';
import { FolderType } from '@mockingjay-io/shared-dependencies/src/types/entities/folder';

export type NestedFolder = Folder & {
  children: NestedFolder[];
};

/**
 * Returns a list of folders with children nested.
 *
 * @param arr
 */
function nestFolders(arr: Folder[]): NestedFolder[] {
  const map = new Map<string, NestedFolder>();
  const nestedArr: NestedFolder[] = [];
  arr.forEach((obj) => {
    if (!map.has(obj.id)) {
      map.set(obj.id, { ...obj, children: [] });
    } else {
      Object.assign(map.get(obj.id)!, obj);
    }
    if (obj.parentId) {
      if (!map.has(obj.parentId)) {
        map.set(obj.parentId, { id: obj.parentId, children: [] } as any);
      }
      map.get(obj.parentId)!.children.push(map.get(obj.id)!);
    } else {
      nestedArr.push(map.get(obj.id)!);
    }
  });
  return nestedArr;
}

/**
 * List folders.
 *
 * @param params
 * @param signal
 */
export async function listFolders(
  params?: SortFilterLimit,
  signal?: AbortSignal
) {
  return (
    await authState.axios.get<FoldersListPaginatedResponse>('folders', {
      signal,
      params,
    })
  ).data;
}

/**
 * Create a new folder.
 *
 * @param request
 * @param signal
 */
export async function createFolder(
  request: FoldersCreateRequest,
  signal?: AbortSignal
): Promise<Folder> {
  return (await authState.axios.post<Folder>('folders', request, { signal }))
    .data;
}

/**
 * Get a folder.
 *
 * @param folderId
 * @param type
 * @param signal
 */
export async function getFolder(
  folderId: string,
  type?: FolderType,
  signal?: AbortSignal
): Promise<Folder> {
  return (
    await authState.axios.get<Folder>(`folders/${folderId}`, {
      params: { type },
      signal,
    })
  ).data;
}

/**
 * Update a folder.
 *
 * @param folderId
 * @param request
 * @param signal
 */
export async function updateFolder(
  folderId: string,
  request: FoldersUpdateRequest,
  signal?: AbortSignal
): Promise<Folder> {
  return (
    await authState.axios.patch<Folder>(`folders/${folderId}`, request, {
      signal,
    })
  ).data;
}

/**
 * Hook to delete a folder.
 */
export async function deleteFolder(
  folderId: string,
  signal?: AbortSignal
): Promise<void> {
  await authState.axios.delete(`folders/${folderId}`, { signal });
}

/**
 * Hook to list folders
 */
export function useFolders(
  params?: SortFilterLimit,
  options?: Omit<UseQueryOptions, 'queryKey' | 'queryFn'>
) {
  if (!params) {
    params = {
      limit: 10000,
      sort: 'name:asc',
    };
  }
  return useQuery<
    FoldersListPaginatedResponse,
    AxiosError<GenericErrorResponse>
  >(['folders', 'list', params], ({ signal }) => listFolders(params, signal), {
    keepPreviousData: true,
    ...(options as any),
    onSuccess: (data) => {
      for (const folder of data.data) {
        queryClient.setQueryData(['folders', folder.id], folder);
      }
    },
  });
}

/**
 * Hook to list folders nested by parent
 *
 * @param params
 * @param options
 */
export function useNestedFolders(
  params?: SortFilterLimit,
  options?: Omit<UseQueryOptions, 'queryKey' | 'queryFn'>
) {
  if (!params) {
    params = {
      limit: 10000,
      sort: 'name:asc',
    };
  }
  return useQuery<NestedFolder[], AxiosError<GenericErrorResponse>>(
    ['folders', 'list', 'nested', params],
    async ({ signal }) => {
      const foldersResponse = await listFolders(params, signal);
      queryClient.setQueryData(['folders', 'list', params], foldersResponse);
      if (foldersResponse && foldersResponse.data) {
        return nestFolders(foldersResponse.data);
      }
      return [];
    },
    {
      keepPreviousData: true,
      ...(options as any),
      onSuccess: (data) => {
        for (const folder of data) {
          queryClient.setQueryData(
            ['folders', folder.id],
            omit(folder, ['children'])
          );
        }
      },
    }
  );
}

/**
 * Hook to get folder
 */
export function useFolder(
  folderId: string,
  type?: FolderType,
  options?: Omit<UseQueryOptions, 'queryKey' | 'queryFn'>
) {
  return useQuery<Folder, AxiosError<GenericErrorResponse>>(
    ['folders', folderId],
    () => getFolder(folderId, type),
    {
      enabled: !!folderId,
      ...(options as any),
    }
  );
}
