import { useRouter } from 'next/router';
import { isValidUUIDv4 } from '@mockingjay-io/shared-dependencies/src/utils/validations';

const TEST_EXECUTION_VIEWS = new Set([
  '/tests/[testId]/executions/[executionId]',
]);

const TEST_RECORDER_VIEWS = new Set([
  '/tests/[testId]/recordings/[recordingId]',
  '/blocks/[blockId]/recordings/[recordingId]',
]);

const TEST_VIEWS = new Set([
  '/tests/[testId]',
  '/blocks/[blockId]',
  ...TEST_RECORDER_VIEWS,
  ...TEST_EXECUTION_VIEWS,
]);

const TESTS_LIST = new Set(['/tests/[testId]', '/tests']);

/**
 * Hook to return the state of the recorder.
 */
export function useTestViewState(): {
  isTestViewOpen: boolean;
  isTestExecutionViewOpen: boolean;
  isTestsListOpen: boolean;
  isRecorderOpen: boolean;
} {
  const { route, query } = useRouter();
  const { testId, blockId } = query as Record<string, string>;
  return {
    isTestExecutionViewOpen: TEST_EXECUTION_VIEWS.has(route),
    isTestsListOpen: TESTS_LIST.has(route) && !isValidUUIDv4(testId || blockId),
    isTestViewOpen: TEST_VIEWS.has(route) && isValidUUIDv4(testId || blockId),
    isRecorderOpen: TEST_RECORDER_VIEWS.has(route),
  };
}
