import { action, makeObservable, observable } from 'mobx';

class AppShellStore {
  @observable isSidebarExpanded = false;
  @observable isSidebarCollapsed = false;

  @action
  toggleSidebar(value?: boolean) {
    this.isSidebarCollapsed = value ?? !this.isSidebarCollapsed;
  }

  constructor() {
    makeObservable(this);
  }
}

const appShellState = new AppShellStore();
export default appShellState;
