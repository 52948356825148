import type { PropsWithChildren, ReactElement } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react-lite';
import authState from '@/store/auth';
import logger from '@/utils/logger';

type AuthGuardProps = {
  publicPaths?: string[];
  anyPaths?: string[];
  authorizedLandingPath?: string;
  loginPath?: string;
};

const AuthGuard = observer(
  ({
    publicPaths = ['/login', '/oauth'],
    anyPaths = ['/version'],
    authorizedLandingPath = '/tests',
    loginPath = '/login',
    children,
  }: PropsWithChildren<AuthGuardProps>) => {
    const router = useRouter();
    const isAuthorized = authState.isAuthenticated;
    const [isVisible, setIsVisible] = useState(false);
    const validateAuth = useCallback(async () => {
      setIsVisible(false);
      let isRedirect = false;
      if (isAuthorized) {
        if (publicPaths?.includes(router.route)) {
          isRedirect = true;
          if (window.sessionStorage.getItem('return_url')) {
            await router.replace(
              window.sessionStorage.getItem('return_url') as string
            );
            window.sessionStorage.removeItem('return_url');
          } else {
            await router.replace(authorizedLandingPath);
          }
        }
      } else {
        if (
          !publicPaths?.includes(router.route) &&
          !anyPaths?.includes(router.route)
        ) {
          isRedirect = true;
          await router.replace({
            pathname: loginPath,
            query: {
              return_url: router.asPath,
            },
          });
        }
      }

      if (!isRedirect) {
        setIsVisible(true);
      }
    }, [
      isAuthorized,
      publicPaths,
      router,
      authorizedLandingPath,
      anyPaths,
      loginPath,
    ]);

    useEffect(() => {
      validateAuth().catch((e) => logger.error(e));
    }, [validateAuth, router.isReady]);

    return (isVisible ? children : null) as ReactElement;
  }
);

export default AuthGuard;
