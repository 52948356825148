import {
  createStyles,
  Group,
  Header as MantineHeader,
  useMantineTheme,
} from '@mantine/core';
import Link from 'next/link';
import UserSettings from '../UserSettings/UserSettings';
import { observer } from 'mobx-react-lite';
import logo from '@/static/images/logo.svg';
import logoWideDark from '@/static/images/logo-wide-dark.svg';
import logoWideLight from '@/static/images/logo-wide-light.svg';

const useStyles = createStyles(() => {
  return {
    header: {
      boxShadow: 'rgb(0 0 0 / 1%) 0px 1px 3px, rgb(0 0 0 / 4%) 0px 1px 2px',
    },
    wrapperGroup: {
      height: '100%',
      marginTop: 0,
      marginBottom: 0,
    },
    brandHolder: {
      verticalAlign: 'middle',
      cursor: 'pointer',
    },
    brandSpacer: {
      display: 'inline-block',
    },
    brandLogoWide: {
      height: 32,
      pointerEvents: 'none',
    },
    brandLogo: {
      width: 32,
      pointerEvents: 'none',
    },
    brandName: {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  };
});

type HeaderProps = {
  height?: number;
  isMinimized?: boolean;
  width?: string | number;
};

function Header({
  height = 60,
  width = '100%',
  isMinimized = false,
}: HeaderProps) {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  // if (appShellState.isSidebarExpanded && !isNaN(width as any)) {
  //   (width as any) *= 2;
  // }
  return (
    <MantineHeader
      height={height}
      zIndex={150}
      p="xs"
      className={classes.header}
      style={{
        width,
      }}
    >
      <Group
        className={classes.wrapperGroup}
        pl={isMinimized ? 0 : 12}
        pr={isMinimized ? 0 : 8}
        position="apart"
      >
        <Link href="/tests" className={classes.brandHolder}>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          {isMinimized ? (
            <img
              src={logo}
              alt="mockingjay.io Logo"
              className={classes.brandLogo}
            />
          ) : (
            <img
              src={theme.colorScheme === 'light' ? logoWideLight : logoWideDark}
              alt="mockingjay.io Logo"
              className={classes.brandLogoWide}
            />
          )}
        </Link>
        <Group>
          <UserSettings />
        </Group>
      </Group>
    </MantineHeader>
  );
}

export default observer(Header);
