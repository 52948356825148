import LZString from 'lz-string';

import { isBrowser } from '@/utils/ssr';
import logger from '@/utils/logger';

const _serverSideStub: any = {
  length: 0,
  setItem(key: string, value: string) {
    this[key] = value;
  },
  getItem(key: string): string | null {
    return this[key] || null;
  },
  removeItem(key: string) {
    delete this[key];
  },
  key(index: number): string | null {
    return Object.keys(this)[index] || null;
  },
};

export class BrowserStore {
  /**
   * Get the current storage backend.
   *
   * @param sessionLevel should it be session level or website level
   */
  static getBackend(sessionLevel = false) {
    if (isBrowser()) {
      return sessionLevel ? sessionStorage : localStorage;
    }

    // Return a stub on the server side.
    return _serverSideStub;
  }

  /**
   * Serialize and save a value to the store.
   *
   * @param key the key to store at
   * @param value the value to store
   * @param sessionLevel should it be session level or website level
   * @param serialize
   */
  static put<T = any>(
    key: string,
    value: T,
    sessionLevel = false,
    serialize = true
  ): void {
    BrowserStore.getBackend(sessionLevel).setItem(
      key,
      LZString.compressToUTF16(
        serialize ? JSON.stringify(value) : (value as any)
      )
    );
  }

  /**
   * Deserialize and get a value from the store
   *
   * @param key the key to read from
   * @param sessionLevel should it be session level or website level
   * @param deserialize
   */
  static get<T = any>(
    key: string,
    sessionLevel = false,
    deserialize = true
  ): T | null | undefined {
    const value = BrowserStore.getBackend(sessionLevel).getItem(key);
    if (!value) {
      return null;
    }
    try {
      const decryptedValue = LZString.decompressFromUTF16(value);
      return deserialize && decryptedValue !== null
        ? JSON.parse(decryptedValue)
        : decryptedValue;
    } catch (e) {
      logger.error({ e, value }, 'error reading browser store value');
      BrowserStore.remove(key);
      return null;
    }
  }

  /**
   * Remove a value from the store
   *
   * @param key the key to read from
   * @param sessionLevel should it be session level or website level
   */
  static remove<T = any>(key: string, sessionLevel = false): void {
    BrowserStore.getBackend(sessionLevel).removeItem(key);
  }

  /**
   * List keys from the store
   *
   * @param sessionLevel should it be session level or website level
   */
  static listKeys<T = any>(sessionLevel = false): string[] {
    return Object.keys(BrowserStore.getBackend(sessionLevel));
  }

  /**
   * Set a cookie with an expiry.
   *
   * @param name
   * @param value
   * @param expirySeconds
   */
  static setCookie(name: string, value: string, expirySeconds: number): void {
    const cookieDate = new Date();
    cookieDate.setTime(cookieDate.getTime() + expirySeconds * 1000);
    const expires = `expires=${cookieDate.toUTCString()}`;
    document.cookie = `${name}=${value};${expires};path=/`;
  }

  /**
   * Get a cookie.
   *
   * @param name
   */
  static getCookie(name: string): string | null {
    const cookieName = `${name}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieParts = decodedCookie.split(';');
    for (let i = 0; i < cookieParts.length; i++) {
      let cookiePart = cookieParts[i];
      while (cookiePart.charAt(0) === ' ') {
        cookiePart = cookiePart.substring(1);
      }
      if (cookiePart.indexOf(cookieName) === 0) {
        return cookiePart.substring(cookieName.length, cookiePart.length);
      }
    }
    return null;
  }
}
