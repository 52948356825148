import { NestedFolder } from '@/services/folders';
import { MantineNumberSize } from '@mantine/core';
import IconButton, {
  IconButtonProps,
} from '@/components/bricks/IconButton/IconButton';
import { useToggle } from '@mantine/hooks';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { FiFolder } from 'react-icons-all-files/fi/FiFolder';
import { FiFolderMinus } from 'react-icons-all-files/fi/FiFolderMinus';
import { FiFolderPlus } from 'react-icons-all-files/fi/FiFolderPlus';
import ActiveLink from '@/components/bricks/ActiveLink/ActiveLink';
import { FoldersGroup } from './FoldersGroup';
import { FolderType } from '@mockingjay-io/shared-dependencies/src/types/entities/folder';

export type FolderItemGroupProps = {
  type: FolderType;
  folder: NestedFolder;
  indent?: MantineNumberSize;
  onCreate?: (parentId?: string) => void;
  slugPrefix?: string;
  iconButtonProps?: Partial<IconButtonProps>;
};

export function FolderItemGroup({
  type,
  folder,
  iconButtonProps,
  indent,
  slugPrefix,
  onCreate,
}: FolderItemGroupProps) {
  const [expanded, toggleExpanded] = useToggle([false, true]);
  const { query } = useRouter();

  const slug = useMemo(() => {
    return slugPrefix ? `${slugPrefix}:${folder.slug}` : folder.slug;
  }, [folder.slug, slugPrefix]);

  const icon = useMemo(() => {
    if (folder.children.length === 0) {
      return <FiFolder size={10} />;
    }
    return expanded ? <FiFolderMinus size={10} /> : <FiFolderPlus size={10} />;
  }, [expanded, folder.children.length]);

  const isActiveLevel = useMemo(() => {
    if (!query.testId) {
      return false;
    }
    return slug === query.testId || (query.testId as string)?.startsWith(slug);
  }, [query.testId, slug]);

  useEffect(() => {
    toggleExpanded(isActiveLevel || false);
  }, [isActiveLevel, toggleExpanded]);

  return (
    <>
      <ActiveLink href={`/${type}/${slug}`}>
        <IconButton
          {...iconButtonProps}
          icon={icon}
          badge={folder?.metadata?.itemsCount || 0}
          badgeProps={{ color: 'gray', variant: 'light', miw: 30 }}
          label={folder.name}
          onIconClick={
            folder.children.length === 0 ? undefined : () => toggleExpanded()
          }
        />
      </ActiveLink>
      {expanded && (
        <FoldersGroup
          type={type}
          parent={folder}
          indent={indent}
          onCreate={onCreate}
          slugPrefix={slug}
        >
          {folder.children}
        </FoldersGroup>
      )}
    </>
  );
}
