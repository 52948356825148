import { action, makeObservable, observable, toJS } from 'mobx';
import config from '@/config';
import axios from 'axios';
import { isBrowser } from '@/utils/ssr';
import type { IdTokenPayload } from '@mockingjay-io/shared-dependencies/src/types/jwt';
import Cognito from '@/utils/cognito';
import tracker from '@/store/tracker';
import { setUser, configureScope } from '@sentry/nextjs';
import { clearQueryCaches } from '@/services';
import { ITeam } from '@mockingjay-io/shared-dependencies/src/types/entities/team';
import { BrowserStore } from '@/utils/storage';
import isPlainObject from 'lodash/isPlainObject';
import logger from '@/utils/logger';
import { v4 as uuidv4 } from 'uuid';

const TEAM_ID_LOCALSTORAGE_KEY = 'x-mj-selected-team';

/**
 * Get the original redirect URL for the OAuth flow.
 */
function getOriginalRedirectUrl() {
  return `${window.location.protocol}//${window.location.host}/login`;
}

const cognito = new Cognito<User & { activeTeam: any }>({
  // Amazon Cognito Region
  region: config.COGNITO_USER_POOL.split('_')[0],

  // Amazon Cognito User Pool ID
  userPoolId: config.COGNITO_USER_POOL,

  // Amazon Cognito Web Client ID (26-char alphanumeric string)
  clientId: config.COGNITO_CLIENT_ID,

  // Buffer for token refreshes
  refreshBufferSeconds: 300,

  localStorageKey: 'x-mj-auth-state',

  oauth: {
    url: `https://${config.COGNITO_OAUTH_DOMAIN}`,
    scope: ['email', 'profile', 'openid'],
    responseType: 'code',
    getRedirectUrl() {
      if (config.USE_API_REDIRECT_FOR_AUTH) {
        return `${config.API_BASE_URL}auth/redirect`;
      }
      return `${window.location.protocol}//${window.location.host}/login`;
    },
    getState() {
      let state = `oauth-state-${uuidv4()}`;
      if (config.USE_API_REDIRECT_FOR_AUTH) {
        state += `=${getOriginalRedirectUrl()}`;
      }
      return state;
    },
  },

  async onAuthentication(tokenResponse) {
    const sessionCreateRes = await axios
      .create()
      .post<{ user: User }>('session/create', undefined, {
        baseURL: config.API_BASE_URL,
        headers: { authorization: tokenResponse.id_token },
      });
    return sessionCreateRes.data.user;
  },
});

export type User = {
  accessToken: string;
  idToken: string;
  idTokenPayload: IdTokenPayload;

  id: string;
  name: string;
  email: string;
  pictureUrl: string;

  lastLoginAt: string;
  createdAt: string;
  updatedAt: string;

  teams: ITeam[];
  activeTeam: ITeam;
};

function createAxiosInstance() {
  const instance = axios.create();
  instance.defaults.baseURL = config.API_BASE_URL;
  instance.interceptors.request.use(function (config) {
    // Auto serialize the filter param if present
    if (
      config.params &&
      config.params.filter &&
      isPlainObject(config.params.filter)
    ) {
      try {
        config.params.filter = JSON.stringify(config.params.filter);
      } catch (e) {
        logger.error(e, 'Failed to stringify filter');
      }
    }
    return config;
  });
  return instance;
}

class AuthStore {
  public isAuthenticated = false;
  public user: Partial<User> = {};
  public axios = createAxiosInstance();

  private reloadAxiosAuthorization() {
    let axiosInstance = this.axios;
    if (
      !axiosInstance ||
      !axiosInstance.defaults.headers.common ||
      axiosInstance.defaults.headers.common.authorization !== this.user.idToken
    ) {
      axiosInstance = createAxiosInstance();
      axiosInstance.defaults.headers.common = {
        authorization: this.user.idToken!,
      };
    }
    axiosInstance.defaults.headers.common['x-team-id'] =
      this.user.activeTeam!.id;
    this.axios = axiosInstance;
  }

  onAuthentication() {
    const currentSession = cognito.getState();
    const selectedTeam =
      BrowserStore.get(TEAM_ID_LOCALSTORAGE_KEY, false, false) ||
      currentSession?.extraAuthData?.teams[0].id;
    this.user = {
      ...currentSession?.extraAuthData,
      activeTeam:
        currentSession?.extraAuthData?.teams.find(
          (team) => team.id === selectedTeam
        ) || currentSession?.extraAuthData?.teams[0],
      idToken: currentSession?.tokenResponse.id_token,
      idTokenPayload: currentSession?.tokenPayload,
    };

    BrowserStore.put(
      TEAM_ID_LOCALSTORAGE_KEY,
      this.user.activeTeam!.id,
      false,
      false
    );
    this.isAuthenticated = true;
    this.reloadAxiosAuthorization();
    const plainUser = toJS(this.user);
    tracker.identify(plainUser as User);
    const {
      email,
      id,
      name,
      activeTeam,
      createdAt,
      updatedAt,
      teams,
      lastLoginAt,
    } = plainUser;
    setUser({
      email,
      id,
      name,
      activeTeam,
      createdAt,
      updatedAt,
      teams,
      lastLoginAt,
    });
  }

  onDeAuthentication() {
    this.isAuthenticated = false;
    for (const prop of Object.getOwnPropertyNames({ ...this.user })) {
      delete (this.user as any)[prop];
    }
    this.user = {};
    this.axios = createAxiosInstance();
    tracker.reset();
    configureScope((scope) => scope.setUser(null));
    setTimeout(() => {
      clearQueryCaches();
    }, 1000);
  }

  switchTeam(team: ITeam) {
    BrowserStore.put(TEAM_ID_LOCALSTORAGE_KEY, team.id, false, false);
    window.location.href = `/${window.location.pathname.split('/')[1] || ''}`;
  }

  async signIn() {
    tracker.track('Sign in');
    await cognito.login();
  }

  async signOut() {
    tracker.track('Sign out');
    await cognito.logout();
  }

  constructor() {
    makeObservable(this, {
      isAuthenticated: observable,
      user: observable,
      onAuthentication: action,
      onDeAuthentication: action,
      switchTeam: action,
    });

    // Run only on client-side
    if (!isBrowser()) {
      return;
    }
    cognito.addEventListener('login', () => {
      this.onAuthentication();
    });
    cognito.addEventListener('logout', () => {
      this.onDeAuthentication();
    });
    cognito.addEventListener('refresh', () => {
      this.onAuthentication();
    });
    const cognitoState = cognito.getState();
    if (cognitoState) {
      this.onAuthentication();
    } else {
      clearQueryCaches();
    }
  }
}

const authState = new AuthStore();
export default authState;
