import type { PropsWithChildren } from 'react';
import { createStyles, Group, Stack } from '@mantine/core';
import Sidebar from '../Sidebar/Sidebar';
import { useTestViewState } from '@/hooks/tests-view';
import Header from '@/components/navigation/Header/Header';
import { useSidebarWidths } from '@/hooks/sidebar';
import Footer from '../Footer/Footer';
import appShellState from '@/store/app-shell';
import { observer } from 'mobx-react-lite';

const useStyles = createStyles((theme) => {
  return {
    root: {
      minWidth: 1024,
      width: '100vw',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
    },
    shell: {
      flexGrow: 1,
    },
    container: {
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      minWidth: '0 !important',
      overflowY: 'scroll',
      overflowX: 'hidden',
      position: 'relative',
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[9]
          : theme.colors.gray[0],
    },
  };
});

function AppShell({ children }: PropsWithChildren<any>) {
  const { isTestViewOpen, isRecorderOpen, isTestExecutionViewOpen } =
    useTestViewState();
  const { width, expandedWidth } = useSidebarWidths();
  const { classes } = useStyles();
  const containerHeight = isRecorderOpen
    ? '100vh'
    : 'calc(100vh - var(--mantine-header-height, 0px) - var(--mantine-footer-height, 0px))';
  return (
    <Stack align="stretch" className={classes.root} spacing={0}>
      {!isRecorderOpen && <Header isMinimized={false} />}
      <Group className={classes.shell} align="stretch" spacing={0} noWrap>
        {!appShellState.isSidebarCollapsed && (
          <Sidebar
            navListHidden={isTestViewOpen}
            width={width}
            expandedWidth={expandedWidth}
          />
        )}
        <div
          style={{
            height: containerHeight,
            flexGrow: 1,
          }}
          className={classes.container}
        >
          <div style={{ flexGrow: 1 }}>{children}</div>
          {isTestExecutionViewOpen || (!isRecorderOpen && <Footer />)}
        </div>
      </Group>
    </Stack>
  );
}

export default observer(AppShell);
