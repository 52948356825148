import { useActionConfirm } from '@/hooks/confirm';
import { LoadingOverlay, Text } from '@mantine/core';
import { invalidateQueries } from '@/utils/react-query';
import tracker from '@/store/tracker';
import { deleteVariable } from '@/services/variables';
import { useModals } from '@mantine/modals';
import dynamic from 'next/dynamic';
import { PartialVariable } from '@mockingjay-io/shared-dependencies/src/inflector/variable';
import { ModalsContextProps } from '@mantine/modals/lib/context';
import { useRouter } from 'next/router';
import { isValidUUIDv4 } from '@mockingjay-io/shared-dependencies/src/utils/validations';
import isArray from 'lodash/isArray';
import { useCallback } from 'react';

const VariablesCreateUpdateForm = dynamic(
  async () =>
    import(
      '@/components/variables/VariablesCreateUpdateForm/VariablesCreateUpdateForm'
    ),
  {
    loading: () => <LoadingOverlay visible />,
  }
);

const VARIABLES_LIST = new Set(['/variables/[folderSlug]', '/variables']);

/**
 * Hook to get the state of the variables list.
 */
export function useVariablesViewState(): { isVariablesListOpen: boolean } {
  const { route, query } = useRouter();
  const { folderSlug } = query as Record<string, string>;
  return {
    isVariablesListOpen:
      VARIABLES_LIST.has(route) && !isValidUUIDv4(folderSlug),
  };
}

/**
 * Hook to create update variable.
 */
export function useCreateUpdateVariableModal(
  folderId?: string | null,
  parentModalsContext?: ModalsContextProps
) {
  const modals = useModals();
  const modalsContext = parentModalsContext || modals;
  return useCallback(
    (
      variable?: PartialVariable,
      onSave?: (variable: PartialVariable) => any,
      noRemote = false
    ) => {
      const id = modalsContext.openModal({
        title: `${variable ? 'Update' : 'Create a'} ${
          noRemote ? 'local' : 'global'
        } variable`,
        size: '70%',
        children: (
          <div style={{ minHeight: 300 }}>
            <VariablesCreateUpdateForm
              data={variable}
              noRemote={noRemote}
              folderId={folderId}
              onSave={(newVariable) => {
                onSave?.(newVariable);
                modalsContext.closeModal(id);
              }}
            />
          </div>
        ),
      });
    },
    [folderId, modalsContext]
  );
}

/**
 * Hook to delete variable and show appropriate notifications & loading state.
 *
 * @param variableId
 * @param variableName
 * @param onDeleteCallback
 */
export function useDeleteVariable(
  variableId?: string | string[],
  variableName?: string,
  onDeleteCallback?: () => any | Promise<any>
) {
  const isMultiple = isArray(variableId) && variableId.length > 1;
  const subject = isMultiple ? 'variables' : 'variable';

  const { loading, executeAction } = useActionConfirm({
    title: `Delete ${subject}`,
    children: (
      <Text size="sm">
        Are you sure you wish to delete the ${subject}? Deleting a variable will
        cause all tests that reference it to be broken.
      </Text>
    ),
    labels: {
      confirm: `Yes, Delete ${subject}.`,
      cancel: 'Cancel',
    },
    confirmProps: {
      color: 'red',
      size: 'xs',
    },
    confirmText: variableName,
    irreversible: true,
    action: async () => {
      if (!variableId) {
        return;
      }
      await deleteVariable(variableId);
      await invalidateQueries(
        ['variables', 'list'],
        ...(isMultiple ? variableId : [variableId]).map((id) => [
          'variables',
          id,
        ])
      );
      tracker.track('Variable > Delete');
      await onDeleteCallback?.();
    },
    successMessage: `The ${subject} ${
      isMultiple ? 'have' : 'has'
    } been deleted successfully.`,
    failureMessage: `There was an error deleting the ${subject}.`,
  });

  return {
    loading,
    executeAction,
  };
}
