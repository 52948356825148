import { QueryClient } from '@tanstack/react-query';
import config from '@/config';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: !config.DEBUG,
      refetchOnReconnect: !config.DEBUG,
    },
  },
});
export const localStoragePersisterKey = 'REACT_QUERY_OFFLINE_CACHE';

/**
 * Clear all react-query caches.
 */
export function clearQueryCaches() {
  queryClient.clear();
  // if (isBrowser()) {
  //   localStorage.removeItem(localStoragePersisterKey);
  // }
}

// if (isBrowser()) {
//   const localStoragePersister = createWebStoragePersister({
//     storage: window.localStorage,
//     retry: removeOldestQuery,
//     key: localStoragePersisterKey,
//   });
//
//   persistQueryClient({
//     queryClient,
//     persister: localStoragePersister,
//   });
// }
