import axios from 'axios';
import { BrowserStore } from '@/utils/storage';
import type { IdTokenPayload } from '@mockingjay-io/shared-dependencies/src/types/jwt';
import jwtDecode from 'jwt-decode';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { buildUrlSearchParams } from '@/utils/requests';
import logger from '@/utils/logger';
import { v4 as uuidv4 } from 'uuid';
import { isBrowser } from '@/utils/ssr';

dayjs.extend(utc);

type TokenResponse = {
  access_token: string;
  refresh_token: string;
  id_token: string;
  token_type: string;
  expires_in: number;
};

type CognitoOptions<AuthData> = {
  // Amazon Cognito Region
  region: string;

  // Amazon Cognito User Pool ID
  userPoolId: string;

  // Amazon Cognito Web Client ID (26-char alphanumeric string)
  clientId: string;

  // The key under which the data should be stored in local-storage
  localStorageKey: string;

  oauth: {
    url: string;
    scope: string[];
    getRedirectUrl: () => string;
    getState?: () => string;
    responseType: 'code';
  };

  refreshBufferSeconds: number;
  onAuthentication?: (tokenResponse: TokenResponse) => Promise<AuthData>;
};

type AuthState<AuthData> = {
  tokenResponse: TokenResponse;
  tokenPayload: IdTokenPayload;
  extraAuthData?: AuthData | null;
};

export default class Cognito<AuthData> extends EventTarget {
  private state: AuthState<AuthData> | null = null;
  private autoRefreshTimeoutHandle: number | null = null;
  private options: CognitoOptions<AuthData>;

  constructor(options: CognitoOptions<AuthData>) {
    super();
    this.options = options;
    this.handleCodeResponse().catch((e) => logger.error(e));
    const storedState = BrowserStore.get<AuthState<AuthData>>(
      this.options.localStorageKey
    );
    if (storedState) {
      this.state = storedState;
      Promise.resolve(this.setAuthRefresh()).catch((e) => logger.error(e));
    }
  }

  private setAuthRefresh() {
    if (this.autoRefreshTimeoutHandle) {
      window.clearTimeout(this.autoRefreshTimeoutHandle);
    }
    if (!this.state) {
      return;
    }
    const refreshDelay =
      this.state.tokenPayload.exp -
        dayjs().utc().unix() -
        this.options.refreshBufferSeconds || 300;

    // If token already expired, refresh it right away
    if (refreshDelay < 0) {
      return this.refresh();
    }

    // Set a timeout to refresh after sometime.
    this.autoRefreshTimeoutHandle = window.setTimeout(() => {
      this.refresh().catch((e) => logger.error(e));
    }, refreshDelay * 1000);
  }

  public async refresh() {
    const data = buildUrlSearchParams({
      grant_type: 'refresh_token',
      client_id: this.options.clientId,
      refresh_token: this.state!.tokenResponse.refresh_token,
    });
    try {
      const res = await axios.post<TokenResponse>(
        `${this.options.oauth.url}/oauth2/token`,
        data
      );
      this.state = {
        ...this.state,
        tokenResponse: {
          ...this.state?.tokenResponse,
          ...res.data,
        },
        tokenPayload: jwtDecode(res.data.id_token) as IdTokenPayload,
      };
      Promise.resolve(this.setAuthRefresh()).catch((e) => logger.error(e));
      BrowserStore.put(this.options.localStorageKey, this.state);
      this.dispatchEvent(new Event('refresh'));
    } catch (e) {
      logger.error(e);
      await this.logout();
    }
  }

  private async handleCodeResponse() {
    if (!isBrowser()) {
      return;
    }
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (!params || !params.code) {
      return;
    }
    const oauthState = BrowserStore.getCookie('oauth-state');
    if (params.state !== oauthState) {
      logger.warn('State mis-match');
      return;
    }

    const oauthNonce = BrowserStore.getCookie('oauth-nonce');
    const data = buildUrlSearchParams({
      grant_type: 'authorization_code',
      client_id: this.options.clientId,
      redirect_uri: this.options.oauth.getRedirectUrl(),
      code: params.code,
    });
    const res = await axios.post<TokenResponse>(
      `${this.options.oauth.url}/oauth2/token`,
      data
    );
    const state: AuthState<AuthData> = {
      tokenResponse: res.data,
      tokenPayload: jwtDecode(res.data.id_token) as IdTokenPayload,
    };

    if (oauthNonce !== state.tokenPayload.nonce) {
      logger.warn('Nonce mis-match');
      return;
    }

    if (this.options.onAuthentication) {
      state.extraAuthData = await this.options.onAuthentication(res.data);
    }
    this.state = state;
    Promise.resolve(this.setAuthRefresh()).catch((e) => logger.error(e));
    BrowserStore.put(this.options.localStorageKey, state);
    this.dispatchEvent(new Event('login'));
  }

  public async login() {
    const redirectUrl = this.options.oauth.getRedirectUrl();
    const state = this.options.oauth.getState?.() || `oauth-state-${uuidv4()}`;
    const nonce = `oauth-nonce-${uuidv4()}`;
    BrowserStore.setCookie('oauth-state', state, 300);
    BrowserStore.setCookie('oauth-nonce', nonce, 300);
    const urlParams = buildUrlSearchParams({
      identity_provider: 'Google',
      client_id: this.options.clientId,
      redirect_uri: redirectUrl,
      response_type: 'code',
      scope: 'email profile openid',
      state,
      nonce,
    });
    window.location.href = `${
      this.options.oauth.url
    }/oauth2/authorize?${urlParams.toString()}`;
  }

  public async logout() {
    if (this.autoRefreshTimeoutHandle) {
      window.clearTimeout(this.autoRefreshTimeoutHandle);
    }
    const data = buildUrlSearchParams({
      client_id: this.options.clientId,
      token: this.state!.tokenResponse.refresh_token,
    });
    await axios.post<TokenResponse>(
      `${this.options.oauth.url}/oauth2/revoke`,
      data
    );
    this.state = null;
    BrowserStore.remove(this.options.localStorageKey);
    this.dispatchEvent(new Event('logout'));
    const logoutData = buildUrlSearchParams({
      client_id: this.options.clientId,
      logout_uri: this.options.oauth.getRedirectUrl(),
    });
    window.location.href = `${
      this.options.oauth.url
    }/logout?${logoutData.toString()}`;
  }

  public getState(): AuthState<AuthData> | null {
    return this.state;
  }
}
