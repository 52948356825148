import { queryClient } from '@/services';
import { QueryKey } from '@tanstack/react-query';

/**
 * Helper to invalidate multiple query keys at a time.
 *
 * @param queryKey
 */
export function invalidateQueries(...queryKey: QueryKey[]) {
  return Promise.all(
    queryKey.map((query) => queryClient.invalidateQueries(query))
  );
}

/**
 * Helper to refetch multiple query keys at a time.
 *
 * @param queryKey
 */
export function refetchQueries(...queryKey: QueryKey[]) {
  return Promise.all(
    queryKey.map((query) => queryClient.refetchQueries(query))
  );
}
