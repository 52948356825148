import '../styles/globals.css';
import Head from 'next/head';
import {
  ColorScheme,
  ColorSchemeProvider,
  createEmotionCache,
  MantineProvider,
  MantineThemeOverride,
} from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import type { AppProps } from 'next/app';
import type { ReactElement, ReactNode } from 'react';
import { useMemo, useState } from 'react';
import type { NextPage } from 'next';
import AuthGuard from '@/components/auth/AuthGuard/AuthGuard';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import localeData from 'dayjs/plugin/localeData';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import { ModalsProvider } from '@mantine/modals';
import AppShell from '@/components/navigation/AppShell/AppShell';
import ChatWidget from '@/components/bricks/ChatWidget/ChatWidget';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from '@/services';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ClarityAnalytics from '@/components/bricks/ClarityAnalytics/ClarityAnalytics';

dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localeData);
dayjs.extend(duration);
dayjs.extend(relativeTime);

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
  getInnerLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const emotionCache = createEmotionCache({ key: 'mantine' });

function _App({ Component, pageProps }: AppPropsWithLayout) {
  const [colorScheme, setColorScheme] = useState<ColorScheme>('light');
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

  const getInnerLayout = Component.getInnerLayout ?? ((page) => page);
  const getLayout =
    Component.getLayout ??
    ((page) => <AppShell>{getInnerLayout(page)}</AppShell>);

  const theme: MantineThemeOverride = useMemo(() => {
    return {
      cursorType: 'pointer',
      components: {
        Modal: {
          styles: () => ({
            root: {
              zIndex: 10000,
            },
          }),
        },
        Drawer: {
          styles: () => ({
            root: {
              zIndex: 10000,
            },
          }),
        },
      },
      colorScheme,
    };
  }, [colorScheme]);

  return (
    <>
      <Head>
        <title>Mockingjay</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/icons/apple-touch-icon.png?v=1"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/icons/favicon-32x32.png?v=1"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/icons/favicon-16x16.png?v=1"
        />
        <link rel="manifest" href="/icons/site.webmanifest?v=1" />
        <link
          rel="mask-icon"
          href="/icons/safari-pinned-tab.svg?v=1"
          color="#009239"
        />
        <link rel="shortcut icon" href="/icons/favicon.ico?v=1" />
        <meta name="msapplication-TileColor" content="#009239" />
        <meta
          name="msapplication-config"
          content="/icons/browserconfig.xml?v=1"
        />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <ChatWidget />
      <ClarityAnalytics />
      <QueryClientProvider client={queryClient}>
        <ColorSchemeProvider
          colorScheme={colorScheme}
          toggleColorScheme={toggleColorScheme}
        >
          <MantineProvider
            emotionCache={emotionCache}
            withGlobalStyles
            withNormalizeCSS
            theme={theme}
          >
            <NotificationsProvider>
              <ModalsProvider>
                <AuthGuard>{getLayout(<Component {...pageProps} />)}</AuthGuard>
              </ModalsProvider>
            </NotificationsProvider>
          </MantineProvider>
        </ColorSchemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
}

// noinspection JSUnusedGlobalSymbols
export default _App;
