import {
  Block,
  BlocksCreateRequest,
  BlocksListPaginatedResponse,
  BlocksUpdateRequest,
} from '@mockingjay-io/shared-dependencies/src/types/api/blocks';
import authState from '@/store/auth';
import { SortFilterLimit } from '@mockingjay-io/shared-dependencies/src/types/api/generic';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { GenericErrorResponse } from '@mockingjay-io/shared-dependencies/src/types/api/errors';
import {
  CreateTestRecordingRequest,
  TestDefinition,
} from '@mockingjay-io/shared-dependencies/src/types/api/test-definitions';
import isArray from 'lodash/isArray';

/**
 * List blocks.
 *
 * @param params
 * @param withDefinition
 * @param signal
 */
export async function listBlocks(
  params?: SortFilterLimit,
  withDefinition = false,
  signal?: AbortSignal
) {
  return (
    await authState.axios.get<BlocksListPaginatedResponse>('blocks', {
      signal,
      params: {
        ...params,
        with_definition: withDefinition,
      },
    })
  ).data;
}

/**
 * Create a new block.
 *
 * @param request
 * @param signal
 */
export async function createBlock(
  request: BlocksCreateRequest,
  signal?: AbortSignal
): Promise<Block> {
  return (await authState.axios.post<Block>('blocks', request, { signal }))
    .data;
}

/**
 * Get a block.
 *
 * @param blockId
 * @param withDefinition
 * @param signal
 */
export async function getBlock(
  blockId: string,
  withDefinition = true,
  signal?: AbortSignal
): Promise<Block> {
  return (
    await authState.axios.get<Block>(`blocks/${blockId}`, {
      signal,
      params: { with_definition: withDefinition },
    })
  ).data;
}

/**
 * Update a block.
 *
 * @param blockId
 * @param data
 * @param signal
 */
export async function updateBlock(
  blockId: string,
  data: Partial<BlocksUpdateRequest>,
  signal?: AbortSignal
): Promise<Block> {
  return (
    await authState.axios.patch<Block>(`blocks/${blockId}`, data, { signal })
  ).data;
}

/**
 * Hook to delete a block.
 */
export async function deleteBlock(
  blockIds: string | string[],
  signal?: AbortSignal
): Promise<void> {
  await Promise.all(
    (isArray(blockIds) ? blockIds : [blockIds]).map((id) =>
      authState.axios.delete(`blocks/${id}`, { signal })
    )
  );
}

/**
 * Create a new block recording.
 *
 * @param blockId
 * @param data
 * @param signal
 */
export async function createBlockRecording(
  blockId: string,
  data?: CreateTestRecordingRequest,
  signal?: AbortSignal
): Promise<TestDefinition> {
  return (
    await authState.axios.post<TestDefinition>(
      `blocks/${blockId}/recordings`,
      data,
      { signal }
    )
  ).data;
}

/**
 * Hook to list blocks
 */
export function useBlocks(
  params?: SortFilterLimit,
  withDefinition = false,
  options?: Omit<
    UseQueryOptions<BlocksListPaginatedResponse>,
    'queryKey' | 'queryFn'
  >
) {
  return useQuery<
    BlocksListPaginatedResponse,
    AxiosError<GenericErrorResponse>
  >(
    ['blocks', 'list', params, withDefinition],
    ({ signal }) => listBlocks(params, withDefinition, signal),
    {
      keepPreviousData: true,
      ...(options as any),
    }
  );
}

/**
 * Hook to get block
 */
export function useBlock(
  blockId: string,
  withDefinition = false,
  options?: Omit<UseQueryOptions<Block>, 'queryKey' | 'queryFn'>
) {
  return useQuery<Block, AxiosError<GenericErrorResponse>>(
    ['blocks', blockId, withDefinition],
    () => getBlock(blockId, withDefinition),
    {
      enabled: !!blockId,
      ...(options as any),
    }
  );
}
