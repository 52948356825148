import { createStyles, Navbar, Paper, ScrollArea, Stack } from '@mantine/core';
import Header from '../Header/Header';
import SidebarExpanded from '@/components/navigation/Sidebar/SidebarExpanded';
import { useTestViewState } from '@/hooks/tests-view';
import IconButton from '@/components/bricks/IconButton/IconButton';
import { FiDatabase } from 'react-icons-all-files/fi/FiDatabase';
import { ImLab } from 'react-icons-all-files/im/ImLab';
import ActiveLink from '@/components/bricks/ActiveLink/ActiveLink';
import { FaCubes } from 'react-icons-all-files/fa/FaCubes';
import { RiFlowChart } from 'react-icons-all-files/ri/RiFlowChart';
import { BiCodeCurly } from 'react-icons-all-files/bi/BiCodeCurly';
import { MdOutlineChecklist } from 'react-icons-all-files/md/MdOutlineChecklist';
import { MouseEventHandler, useCallback, useEffect, useRef } from 'react';
import SidebarFoldersList from '@/components/folders/SidebarFoldersList/SidebarFoldersList';
import { FolderType } from '@mockingjay-io/shared-dependencies/src/types/entities/folder';
import { useBlocksViewState } from '@/hooks/blocks';
import { useVariablesViewState } from '@/hooks/variables';

type SidebarProps = {
  navListHidden: boolean;
  width?: number;
  expandedWidth?: number;
};

const useStyles = createStyles((theme) => {
  return {
    root: {
      top: 0,
      flexShrink: 0,
    },
    navList: {
      height: '100%',
    },
    mainSection: {
      height: 'unset',
      marginTop: '0px !important',
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[9]
          : theme.colors.gray[0],
    },
    expandedSection: {
      flexShrink: 0,
      boxShadow: 'rgb(0 0 0 / 5%) 0px 1px 3px, rgb(0 0 0 / 10%) 0px 1px 2px;',
    },
    scrollBar: {
      width: '8px !important',
      '> .mantine-ScrollArea-thumb::before': {
        minWidth: 4,
      },
    },
    scrollViewport: {
      '> div': {
        height: '100%',
        display: 'block !important' as any,
        overflowWrap: 'anywhere !important' as any,
        overflowX: 'hidden !important' as any,
      },
    },
    dragger: {
      width: 5,
      cursor: 'ew-resize',
      padding: '0 0 0',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      zIndex: 100,
    },
  };
});

function Sidebar({ navListHidden, width = 400, expandedWidth }: SidebarProps) {
  const { isRecorderOpen, isTestsListOpen } = useTestViewState();
  const { isBlocksListOpen } = useBlocksViewState();
  const { isVariablesListOpen } = useVariablesViewState();
  const navbarRef = useRef<HTMLDivElement>(null);
  const expandedNavbarRef = useRef<HTMLDivElement>(null);
  const { classes } = useStyles();

  if (typeof expandedWidth === 'undefined') {
    expandedWidth = width;
  }

  const handleMouseMove = useCallback(
    (e: any) => {
      const newWidth = e.clientX - document.body.offsetLeft;
      if (navbarRef.current && newWidth > width && newWidth < 600) {
        navbarRef.current.style.width = `${newWidth}px`;
        if (expandedNavbarRef.current) {
          expandedNavbarRef.current.style.left = `${newWidth}px`;
        }
      }
    },
    [width]
  );

  const handleMouseUp = () => {
    document.removeEventListener('mouseup', handleMouseUp, true);
    document.removeEventListener('mousemove', handleMouseMove, true);
  };

  const handleMouseDown: MouseEventHandler = (e) => {
    e.preventDefault();
    document.addEventListener('mouseup', handleMouseUp, true);
    document.addEventListener('mousemove', handleMouseMove, true);
  };

  useEffect(() => {
    if (navbarRef.current) {
      navbarRef.current.style.width = `${width}px`;
      if (expandedNavbarRef.current) {
        expandedNavbarRef.current.style.left = `${width}px`;
      }
    }
  }, [width]);

  return (
    <>
      <Navbar
        ref={navbarRef}
        width={{ base: width }}
        classNames={{ root: classes.root }}
        p={0}
        style={{
          height: isRecorderOpen ? '100vh' : '',
        }}
      >
        {isRecorderOpen && <Header isMinimized width={width} />}
        <Navbar.Section
          grow
          mt="xs"
          component={ScrollArea}
          styles={{
            scrollbar: classes.scrollBar,
            viewport: classes.scrollViewport,
          }}
          className={classes.mainSection}
        >
          {!isRecorderOpen && (
            <div
              role="button"
              tabIndex={0}
              onMouseDown={handleMouseDown}
              className={classes.dragger}
            />
          )}

          <div id="sidebar-portal" />
          {!navListHidden ? (
            <Paper p="sm" className={classes.navList}>
              <Stack spacing={4}>
                <ActiveLink href="/tests">
                  <IconButton icon={<ImLab />} label="Tests" />
                </ActiveLink>
                {isTestsListOpen && (
                  <SidebarFoldersList type={FolderType.Tests} />
                )}
                <ActiveLink href="/executions">
                  <IconButton
                    icon={<MdOutlineChecklist />}
                    label="Executions"
                  />
                </ActiveLink>
                <ActiveLink href="/blocks">
                  <IconButton icon={<FaCubes />} label="Blocks" />
                </ActiveLink>
                {isBlocksListOpen && (
                  <SidebarFoldersList type={FolderType.Blocks} />
                )}
                <ActiveLink href="/variables">
                  <IconButton icon={<BiCodeCurly />} label="Variables" />
                </ActiveLink>
                {isVariablesListOpen && (
                  <SidebarFoldersList type={FolderType.Variables} />
                )}

                <ActiveLink href="/suites">
                  <IconButton icon={<RiFlowChart />} label="Suites" />
                </ActiveLink>
                <ActiveLink href="/assets">
                  <IconButton icon={<FiDatabase />} label="Assets" />
                </ActiveLink>
              </Stack>
            </Paper>
          ) : null}
        </Navbar.Section>
        {/* eslint-disable-next-line react/no-children-prop */}
        <Navbar.Section id="sidebar-actions-portal" children={[]} />{' '}
      </Navbar>
      <SidebarExpanded
        ref={expandedNavbarRef}
        width={expandedWidth}
        offset={width}
        classes={classes}
      />
    </>
  );
}

export default Sidebar;
