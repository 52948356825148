import { useActionConfirm } from '@/hooks/confirm';
import { Text } from '@mantine/core';
import { invalidateQueries } from '@/utils/react-query';
import tracker from '@/store/tracker';
import { deleteBlock } from '@/services/blocks';
import { useRouter } from 'next/router';
import { isValidUUIDv4 } from '@mockingjay-io/shared-dependencies/src/utils/validations';
import isArray from 'lodash/isArray';

const BLOCKS_LIST = new Set(['/blocks/[blockId]', '/blocks']);

/**
 * Hook to get the state of the blocks list.
 */
export function useBlocksViewState(): { isBlocksListOpen: boolean } {
  const { route, query } = useRouter();
  const { blockId } = query as Record<string, string>;
  return {
    isBlocksListOpen: BLOCKS_LIST.has(route) && !isValidUUIDv4(blockId),
  };
}

/**
 * Hook to delete block and show appropriate notifications & loading state.
 *
 * @param blockId
 * @param blockName
 * @param onDeleteCallback
 */
export function useDeleteBlock(
  blockId: string | string[],
  blockName?: string,
  onDeleteCallback?: () => void | Promise<void>
) {
  const isMultiple = isArray(blockId) && blockId.length > 1;
  const subject = isMultiple ? 'blocks' : 'block';

  const { loading, executeAction } = useActionConfirm({
    title: isMultiple ? 'Delete blocks' : 'Delete block',
    children: (
      <Text size="sm">
        Are you sure you wish to delete the {subject}? All tests that are still
        linked to these blocks will no longer work as expected after this
        operation. Please ensure blocks are detached from all tests before
        deleting.
      </Text>
    ),
    irreversible: true,
    confirmText: blockName,
    labels: {
      confirm: `Yes, Delete ${subject}.`,
      cancel: 'Cancel',
    },
    confirmProps: {
      color: 'red',
      size: 'xs',
    },
    action: async () => {
      await deleteBlock(blockId);
      await invalidateQueries(
        ['blocks', 'list'],
        ...(isMultiple ? blockId : [blockId]).map((id) => ['blocks', id])
      );
      tracker.track('Block > Delete');
      await onDeleteCallback?.();
    },
    successMessage: `The ${subject} ${
      isMultiple ? 'have' : 'has'
    } been deleted successfully.`,
    failureMessage: `There was an error deleting the ${subject}.`,
  });

  return {
    loading,
    executeAction,
  };
}
