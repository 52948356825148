import Script from 'next/script';
import authState, { User } from '@/store/auth';
import { useEffect } from 'react';
import { reaction } from 'mobx';
import config from '@/config';

export function toggleChatWidgetVisibility(visible: boolean): void {
  const { fcWidget } = window as any;
  if (!fcWidget || !fcWidget.isInitialized()) {
    return;
  }
  if (visible) {
    fcWidget.show();
  } else {
    fcWidget.hide();
  }
}

export function setupChatWidget(
  isAuthenticated: boolean,
  user: Partial<User>
): void {
  const { fcWidget } = window as any;
  if (!fcWidget) {
    return;
  }
  const { FRESH_CHAT_TOKEN, FRESH_CHAT_HOST } = config;
  if (!FRESH_CHAT_TOKEN || !FRESH_CHAT_HOST) {
    return;
  }
  if (isAuthenticated) {
    fcWidget.init({
      token: FRESH_CHAT_TOKEN,
      host: FRESH_CHAT_HOST,
      externalId: user.id,
      firstName: user.idTokenPayload?.given_name || user.name,
      lastName: user.idTokenPayload?.family_name,
      email: user.email,
    });
  } else {
    if (fcWidget.isInitialized()) {
      fcWidget.destroy();
    }
  }
}

function ChatWidget() {
  useEffect(() => {
    return reaction(
      () =>
        [authState.isAuthenticated, authState.user] as [boolean, Partial<User>],
      ([isAuthenticated, user]) => {
        setupChatWidget(isAuthenticated, user);
      }
    );
  }, []);

  return (
    <Script
      src="https://wchat.freshchat.com/js/widget.js"
      onLoad={() => setupChatWidget(authState.isAuthenticated, authState.user)}
    />
  );
}

export default ChatWidget;
