import { MantineNumberSize } from '@mantine/core';
import { useNestedFolders } from '@/services/folders';
import { useModals } from '@mantine/modals';
import { FoldersGroup } from './FoldersGroup';
import { CreateFolderForm } from './CreateFolderForm';
import { FolderType } from '@mockingjay-io/shared-dependencies/src/types/entities/folder';

type SidebarFoldersListProps = {
  indent?: MantineNumberSize;
  type: FolderType;
};

function SidebarFoldersList({ type, indent }: SidebarFoldersListProps) {
  const modals = useModals();
  const { data: nestedFolders } = useNestedFolders({
    limit: 10000,
    sort: 'name:asc',
    filter: {
      type,
    },
  });

  const openContentModal = (parentId?: string) => {
    const id = modals.openModal({
      title: `Create a ${parentId ? 'sub-' : ''}folder`,
      children: (
        <CreateFolderForm
          type={type}
          parentId={parentId}
          onCreate={() => modals.closeModal(id)}
        />
      ),
    });
  };

  return (
    <FoldersGroup onCreate={openContentModal} type={type} indent={indent}>
      {nestedFolders || []}
    </FoldersGroup>
  );
}

export default SidebarFoldersList;
