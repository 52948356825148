import pino from 'pino';

const logger = pino({
  browser: {
    asObject: false,
  },
  prettyPrint: false,
});

export default logger;
