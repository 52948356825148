import { observer } from 'mobx-react-lite';
import { Navbar, ScrollArea } from '@mantine/core';
import appShellState from '@/store/app-shell';
import { useTestViewState } from '@/hooks/tests-view';
import { forwardRef } from 'react';

type SidebarExpandedProps = {
  width: number;
  offset?: number;
  classes: Record<string, string>;
};

const SidebarExpanded = forwardRef<HTMLDivElement, SidebarExpandedProps>(
  ({ offset, width, classes }, ref) => {
    const { isTestViewOpen, isRecorderOpen } = useTestViewState();
    const isExpanded = appShellState.isSidebarExpanded && isTestViewOpen;
    return (
      <Navbar
        ref={ref}
        style={{
          width: isExpanded ? undefined : 0,
          position: 'absolute',
          left: offset,
          top: isRecorderOpen ? 0 : undefined,
          height: isRecorderOpen ? '100vh' : '',
        }}
        className={classes.expandedSection}
        width={{ base: isExpanded ? width : 0 }}
        p={0}
        styles={{
          root: {
            border: isExpanded ? '' : 'none',
            overflowX: 'hidden',
          },
        }}
      >
        <Navbar.Section
          grow
          mt="xs"
          component={ScrollArea}
          styles={{
            root: {
              width: '100%',
            },
            scrollbar: classes.scrollBar,
            viewport: classes.scrollViewport,
          }}
          className={classes.mainSection}
        >
          <div id="sidebar-expanded-portal" />
        </Navbar.Section>
      </Navbar>
    );
  }
);
SidebarExpanded.displayName = 'SidebarExpanded';

export default observer(SidebarExpanded);
