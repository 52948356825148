import { Text, Stack } from '@mantine/core';
import logo from '@/static/images/logo.svg';

function Footer() {
  return (
    <Stack
      spacing="sm"
      pt={40}
      pb="xl"
      align="center"
      style={{
        width: '100%',
        position: 'relative',
        bottom: 0,
        pointerEvents: 'none',
      }}
    >
      <img alt="Mockingjay logo" src={logo} width={25} />
      <Text size="sm" color="dimmed">
        Copyright &copy; {new Date().getFullYear()} Mockingjay Pte Ltd.
      </Text>
    </Stack>
  );
}

export default Footer;
