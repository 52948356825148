import {
  Variable,
  VariablesCreateRequest,
  VariablesListPaginatedResponse,
  VariablesUpdateRequest,
} from '@mockingjay-io/shared-dependencies/src/types/api/variables';
import authState from '@/store/auth';
import { SortFilterLimit } from '@mockingjay-io/shared-dependencies/src/types/api/generic';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { GenericErrorResponse } from '@mockingjay-io/shared-dependencies/src/types/api/errors';
import isArray from 'lodash/isArray';

/**
 * List variables.
 *
 * @param params
 * @param withDefinition
 * @param signal
 */
export async function listVariables(
  params?: SortFilterLimit,
  withDefinition = false,
  signal?: AbortSignal
) {
  return (
    await authState.axios.get<VariablesListPaginatedResponse>('variables', {
      signal,
      params: {
        ...params,
        with_definition: withDefinition,
      },
    })
  ).data;
}

/**
 * Create a new variable.
 *
 * @param request
 * @param signal
 */
export async function createVariable(
  request: VariablesCreateRequest,
  signal?: AbortSignal
): Promise<Variable> {
  return (
    await authState.axios.post<Variable>('variables', request, { signal })
  ).data;
}

/**
 * Get a variable.
 *
 * @param variableId
 * @param withDefinition
 * @param signal
 */
export async function getVariable(
  variableId: string,
  withDefinition = true,
  signal?: AbortSignal
): Promise<Variable> {
  return (
    await authState.axios.get<Variable>(`variables/${variableId}`, {
      signal,
      params: { with_definition: withDefinition },
    })
  ).data;
}

/**
 * Update a  variable.
 *
 * @param variableId
 * @param request
 * @param signal
 */
export async function updateVariable(
  variableId: string,
  request: VariablesUpdateRequest,
  signal?: AbortSignal
): Promise<Variable> {
  return (
    await authState.axios.patch<Variable>(`variables/${variableId}`, request, {
      signal,
    })
  ).data;
}

/**
 * Hook to delete a variable.
 */
export async function deleteVariable(
  variableIds: string | string[],
  signal?: AbortSignal
): Promise<void> {
  await Promise.all(
    (isArray(variableIds) ? variableIds : [variableIds]).map((id) =>
      authState.axios.delete(`variables/${id}`, { signal })
    )
  );
}

/**
 * Hook to list variables
 */
export function useVariables(
  params?: SortFilterLimit,
  withDefinition = false,
  options?: Omit<UseQueryOptions, 'queryKey' | 'queryFn'>
) {
  if (!params) {
    params = {
      limit: 10000,
    };
  }
  return useQuery<
    VariablesListPaginatedResponse,
    AxiosError<GenericErrorResponse>
  >(
    ['variables', 'list', params, withDefinition],
    ({ signal }) => listVariables(params, withDefinition, signal),
    {
      keepPreviousData: true,
      ...(options as any),
    }
  );
}

/**
 * Hook to get variable
 */
export function useVariable(
  variableId: string,
  withDefinition = false,
  options?: Omit<UseQueryOptions, 'queryKey' | 'queryFn'>
) {
  return useQuery<Variable, AxiosError<GenericErrorResponse>>(
    ['variables', variableId, withDefinition],
    () => getVariable(variableId, withDefinition),
    {
      enabled: !!variableId,
      ...(options as any),
    }
  );
}
