import { NestedFolder } from '@/services/folders';
import { MantineNumberSize, Stack } from '@mantine/core';
import { useMemo } from 'react';
import IconButton, {
  IconButtonProps,
} from '@/components/bricks/IconButton/IconButton';
import { FolderItemGroup } from './FolderItemGroup';
import { FiFolderPlus } from 'react-icons-all-files/fi/FiFolderPlus';
import { FolderType } from '@mockingjay-io/shared-dependencies/src/types/entities/folder';

type FoldersGroupProps = {
  type: FolderType;
  parent?: NestedFolder;
  children: NestedFolder[];
  indent?: MantineNumberSize;
  onCreate?: (parentId?: string) => void;
  slugPrefix?: string;
};

export function FoldersGroup({
  type,
  parent,
  children,
  indent,
  onCreate,
  slugPrefix = '',
}: FoldersGroupProps) {
  const defaultIconButtonProps = useMemo(
    () =>
      ({
        pl: indent,
        iconProps: {
          size: 'sm',
        },
        labelProps: {
          size: 'xs',
        },
        groupProps: {
          spacing: 'xs',
        },
      } as Partial<IconButtonProps>),
    [indent]
  );

  return (
    <Stack align="stretch" style={{ width: '100%' }} pl="xs" spacing={0}>
      {children.map((folder) => (
        <FolderItemGroup
          type={type}
          key={folder.id}
          folder={folder}
          indent={indent}
          slugPrefix={slugPrefix}
          iconButtonProps={defaultIconButtonProps}
          onCreate={onCreate}
        />
      ))}
      <IconButton
        {...defaultIconButtonProps}
        icon={<FiFolderPlus size={10} />}
        onClick={() => onCreate?.(parent?.id)}
        color="gray"
        label="Create folder"
      />
    </Stack>
  );
}
