import { showNotification } from '@mantine/notifications';
import { useModals } from '@mantine/modals';
import { useCallback, useState, MouseEvent, useMemo } from 'react';
import {
  Alert,
  Box,
  Button,
  Group,
  ModalProps,
  Text,
  TextInput,
  ThemeIcon,
} from '@mantine/core';
import type { ConfirmModalProps } from '@mantine/modals/lib/ConfirmModal';
import { FiAlertTriangle } from 'react-icons-all-files/fi/FiAlertTriangle';

type ConfirmationModalProps = Omit<
  ConfirmModalProps,
  'closeOnConfirm' | 'closeOnCancel' | 'id'
> & {
  confirmText?: string;
  irreversible?: boolean;
};

export function ConfirmationModal({
  cancelProps,
  confirmProps,
  confirmText,
  labels = { cancel: '', confirm: '' },
  groupProps,
  irreversible,
  onCancel,
  onConfirm,
  children,
}: ConfirmationModalProps) {
  const [loading, setLoading] = useState(false);
  const [confirmBoxValue, setConfirmBoxValue] = useState('');
  const { cancel: cancelLabel, confirm: confirmLabel } = labels;
  const handleCancel = (event: MouseEvent<HTMLButtonElement>) => {
    typeof cancelProps?.onClick === 'function' && cancelProps?.onClick(event);
    typeof onCancel === 'function' && onCancel();
  };
  const handleConfirm = (event: MouseEvent<HTMLButtonElement>) => {
    typeof confirmProps?.onClick === 'function' && confirmProps?.onClick(event);
    if (typeof onConfirm === 'function') {
      setLoading(true);
      Promise.resolve(onConfirm()).finally(() => {
        setLoading(false);
      });
    }
  };
  const isConfirmDisabled = useMemo(() => {
    if (loading) {
      return true;
    }
    if (!confirmText) {
      return false;
    }
    return confirmBoxValue !== confirmText;
  }, [confirmBoxValue, confirmText, loading]);
  return (
    <>
      {irreversible && (
        <Alert p="xs" color="red" mb="xs">
          <ThemeIcon color="red" variant="light">
            <FiAlertTriangle />
          </ThemeIcon>
          This action is irreversible.
        </Alert>
      )}
      {children && <Box>{children}</Box>}
      {confirmText && (
        <Box mt="xs">
          <TextInput
            label={
              <Text weight={400}>
                Please type <strong>{confirmText}</strong> to confirm.
              </Text>
            }
            value={confirmBoxValue}
            onChange={(event) => setConfirmBoxValue(event.currentTarget.value)}
            required
            withAsterisk={false}
          />
        </Box>
      )}
      <Group position="right" mt="md" {...groupProps}>
        <Button
          variant="default"
          {...cancelProps}
          onClick={handleCancel}
          disabled={loading}
        >
          {cancelProps?.children || cancelLabel}
        </Button>

        <Button
          {...confirmProps}
          onClick={handleConfirm}
          disabled={isConfirmDisabled}
          loading={loading}
        >
          {confirmProps?.children || confirmLabel}
        </Button>
      </Group>
    </>
  );
}

type UseActionConfirmOptions = Partial<
  Pick<
    ConfirmationModalProps,
    | 'labels'
    | 'cancelProps'
    | 'confirmProps'
    | 'children'
    | 'confirmText'
    | 'irreversible'
  > &
    Pick<ModalProps, 'title' | 'size'>
> & {
  action: () => Promise<any>;
  successMessage?: string | null;
  failureMessage?: string | null;
};

/**
 * Hook to get confirmation via a modal for an action
 **/
export function useActionConfirm({
  title = 'Confirmation',
  children = <Text size="sm">Are you sure you wish to continue ?</Text>,
  size = 'sm',
  action,
  irreversible,
  confirmText,
  successMessage,
  failureMessage = 'There was an error when performing that action. Please try again.',
  labels = {
    confirm: 'Yes, Continue.',
    cancel: 'Cancel',
  },
  cancelProps = {
    size: 'xs',
    variant: 'subtle',
    color: 'gray',
  },
  confirmProps = {
    size: 'xs',
  },
}: UseActionConfirmOptions) {
  const modals = useModals();
  const [loading, setLoading] = useState(false);

  const executeAction = useCallback(async () => {
    const modalId = modals.openModal({
      title,
      children: (
        <ConfirmationModal
          labels={labels}
          confirmText={confirmText}
          cancelProps={cancelProps}
          confirmProps={confirmProps}
          irreversible={irreversible}
          onCancel={() => modals.closeModal(modalId)}
          onConfirm={async () => {
            setLoading(true);
            try {
              await action();
              successMessage &&
                showNotification({
                  message: successMessage,
                  color: 'green',
                });
            } catch (e) {
              failureMessage &&
                showNotification({
                  message: failureMessage,
                  color: 'red',
                });
            } finally {
              setLoading(false);
              modals.closeModal(modalId);
            }
          }}
        >
          {children}
        </ConfirmationModal>
      ),
      size,
    });
  }, [
    modals,
    title,
    labels,
    confirmText,
    cancelProps,
    confirmProps,
    irreversible,
    children,
    size,
    action,
    successMessage,
    failureMessage,
  ]);
  return {
    loading,
    executeAction,
  };
}
