import { FormEvent, useCallback, useState } from 'react';
import { createFolder } from '@/services/folders';
import { invalidateQueries } from '@/utils/react-query';
import { AxiosError } from 'axios';
import logger from '@/utils/logger';
import { showNotification } from '@mantine/notifications';
import { Button, TextInput } from '@mantine/core';
import { FiFolderPlus } from 'react-icons-all-files/fi/FiFolderPlus';
import { FolderType } from '@mockingjay-io/shared-dependencies/src/types/entities/folder';

type CreateFolderFormProps = {
  type: FolderType;
  parentId?: string;
  onCreate?: () => void;
};

export function CreateFolderForm({
  type,
  onCreate,
  parentId,
}: CreateFolderFormProps) {
  const [loading, setLoading] = useState(false);
  const onSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setLoading(true);
      const data = new FormData(event.currentTarget);
      createFolder({
        type,
        parentId,
        name: data.get('name')!.toString(),
      })
        .then(async () => {
          await invalidateQueries(['folders', 'list']);
          onCreate?.();
        })
        .catch((e: AxiosError<any>) => {
          logger.error(e, 'there was an error creating the folder.');
          showNotification({
            message:
              e.response?.status === 400 && e.response?.data?.message
                ? e.response?.data?.message
                : `There was an error creating the folder.`,
            color: 'red',
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [type, parentId, onCreate]
  );

  return (
    <form onSubmit={onSubmit}>
      <TextInput
        label="Name"
        name="name"
        minLength={1}
        disabled={loading}
        placeholder="Give a short & sweet name for your folder :)"
        data-autofocus
        required
      />
      <Button
        loading={loading}
        type="submit"
        leftIcon={<FiFolderPlus />}
        fullWidth
        mt="md"
      >
        Create
      </Button>
    </form>
  );
}
