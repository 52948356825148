import {
  Avatar,
  createStyles,
  Group,
  Menu,
  Text,
  UnstyledButton,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { observer } from 'mobx-react-lite';
import authState from '@/store/auth';
import { MdLightMode } from 'react-icons-all-files/md/MdLightMode';
import { MdModeNight } from 'react-icons-all-files/md/MdModeNight';
import { IoMdLogOut } from 'react-icons-all-files/io/IoMdLogOut';
import { FiSettings } from 'react-icons-all-files/fi/FiSettings';
import { FiCheckCircle } from 'react-icons-all-files/fi/FiCheckCircle';
import { FiCircle } from 'react-icons-all-files/fi/FiCircle';
import { useCallback } from 'react';
import { ITeam } from '@mockingjay-io/shared-dependencies/src/types/entities/team';
import { FiMail } from 'react-icons-all-files/fi/FiMail';
import Link from 'next/link';

const useStyles = createStyles(() => {
  return {
    menuButton: {
      marginBottom: -8,
    },
    menuDropdown: {
      minWidth: 300,
    },
    unselectable: {
      pointerEvents: 'none',
    },
  };
});

function UserSettings() {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const delayedToggleColorScheme = () => {
    setTimeout(() => toggleColorScheme(), 100);
  };

  const pictureUrl = authState.user?.pictureUrl || '';

  const onTeamSelection = useCallback((team: ITeam) => {
    authState.switchTeam(team);
  }, []);

  return (
    <Menu
      shadow="md"
      position="bottom-end"
      withArrow
      classNames={{ dropdown: classes.menuDropdown }}
    >
      <Menu.Target>
        <UnstyledButton className={classes.menuButton}>
          <Avatar
            src={pictureUrl}
            imageProps={{ referrerPolicy: 'no-referrer' }}
          />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item className={classes.unselectable}>
          <Group>
            <Avatar
              radius="xl"
              src={pictureUrl}
              imageProps={{ referrerPolicy: 'no-referrer' }}
            />
            <div>
              <Text weight={500}>{authState.user?.name || ''}</Text>
              <Text size="xs" color="dimmed">
                {authState.user?.email || ''}
              </Text>
            </div>
          </Group>
        </Menu.Item>

        <Menu.Divider />
        <Menu.Label>Teams</Menu.Label>

        {authState.user?.teams?.map((team) => {
          const selected = team.id === authState.user?.activeTeam?.id;
          return (
            <Menu.Item
              key={team.id}
              className={selected ? classes.unselectable : ''}
              onClick={() => onTeamSelection(team)}
              icon={
                selected ? (
                  <FiCheckCircle size={14} color={theme.colors.green[6]} />
                ) : (
                  <FiCircle size={14} style={{ opacity: 0.6 }} />
                )
              }
            >
              {team.name}
            </Menu.Item>
          );
        })}

        <Menu.Label>Settings</Menu.Label>
        {colorScheme === 'dark' ? (
          <Menu.Item icon={<MdLightMode />} onClick={delayedToggleColorScheme}>
            Light Mode
          </Menu.Item>
        ) : (
          <Menu.Item icon={<MdModeNight />} onClick={delayedToggleColorScheme}>
            Dark Mode
          </Menu.Item>
        )}
        <Menu.Item component={Link} href="/mailbox" icon={<FiMail size={14} />}>
          Mailbox
        </Menu.Item>
        <Menu.Item
          component={Link}
          href="/settings"
          icon={<FiSettings size={14} />}
        >
          Settings
        </Menu.Item>
        <Menu.Item icon={<IoMdLogOut />} onClick={() => authState.signOut()}>
          Logout
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}

export default observer(UserSettings);
