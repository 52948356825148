import {
  Badge,
  BadgeProps,
  Box,
  BoxProps,
  createStyles,
  DefaultMantineColor,
  Group,
  GroupProps,
  Text,
  TextProps,
  ThemeIcon,
  ThemeIconProps,
} from '@mantine/core';
import type { ReactNode } from 'react';
import { forwardRef, MouseEventHandler, useCallback } from 'react';

export type IconButtonProps = Omit<BoxProps, 'component'> & {
  icon: ReactNode;
  className?: string;
  href?: string;
  ref?: any;
  color?: DefaultMantineColor;
  label: string;
  badge?: string | number;
  groupProps?: Partial<GroupProps>;
  badgeProps?: Partial<BadgeProps>;
  iconProps?: Partial<ThemeIconProps>;
  labelProps?: Partial<TextProps>;
  onClick?: () => any;
  onIconClick?: () => any;
};

const useStyles = createStyles((theme) => {
  return {
    root: {
      cursor: 'pointer',
      display: 'block',
      width: '100%',
      paddingTop: theme.spacing.xs / 2,
      paddingBottom: theme.spacing.xs / 2,
      paddingRight: theme.spacing.xs,
      paddingLeft: theme.spacing.xs,
      borderRadius: theme.radius.sm,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
      textDecoration: 'none',

      '&:hover,&.active': {
        backgroundColor:
          theme.colorScheme === 'dark'
            ? theme.colors.dark[6]
            : theme.colors.gray[0],
      },
    },
  };
});

const IconButton = forwardRef(
  (
    {
      icon,
      color,
      label,
      badge,
      className,
      groupProps,
      iconProps,
      badgeProps,
      labelProps,
      onIconClick,
      ...props
    }: IconButtonProps,
    ref
  ) => {
    const { classes, cx } = useStyles();
    const onIconClickCallback: MouseEventHandler = useCallback(
      (event) => {
        if (!onIconClick) {
          return;
        }
        event.preventDefault();
        event.stopPropagation();
        onIconClick();
      },
      [onIconClick]
    );
    return (
      <Box
        component="a"
        ref={ref}
        className={cx(classes.root, className)}
        {...props}
      >
        <Group position="apart" spacing="sm" noWrap {...groupProps}>
          <Group spacing="xs" noWrap>
            <ThemeIcon
              onClick={onIconClickCallback}
              color={color}
              variant="light"
              {...iconProps}
            >
              {icon}
            </ThemeIcon>
            <Text lineClamp={1} size="sm" {...labelProps}>
              {label}
            </Text>
          </Group>
          {typeof badge !== 'undefined' && (
            <Badge size="xs" {...badgeProps}>
              {badge}
            </Badge>
          )}
        </Group>
      </Box>
    );
  }
);

IconButton.displayName = 'IconButton';
export default IconButton;
