import { useViewportSize } from '@mantine/hooks';
import { useTestViewState } from '@/hooks/tests-view';

const SIDEBAR_PERCENTAGE = 0.25;
const MIN_WIDTH_PX = 250;
const MAX_WIDTH_PX = 400;

/**
 * Get the widths to use for the sidebar.
 */
export function useSidebarWidths(): { width: number; expandedWidth: number } {
  const { width: viewportWidth } = useViewportSize();
  const { isRecorderOpen, isTestViewOpen } = useTestViewState();

  const width = Math.min(
    Math.max(
      Math.floor(
        viewportWidth *
          (isRecorderOpen || isTestViewOpen ? SIDEBAR_PERCENTAGE : 0.16)
      ),
      MIN_WIDTH_PX
    ),
    MAX_WIDTH_PX
  );
  const expandedWidth = width * 2;
  return {
    width,
    expandedWidth,
  };
}
